/* stylelint-disable scss/at-import-partial-extension */
@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/variables.override';
@import '../../style/abstract/button.override';

:root {
    --cart-item-price-color: var(--primary-error-color);
    --cart-item-price-discount-color: var(--body-content-secondary-color);
}

.CartItem {
    &-Price {
        &.ProductPrice {
            font-size: 20px;
            line-height: 1;
            color: var(--cart-item-price-color);
            display: flex;
            flex-direction: column;

            del {
                font-size: 14px;
                line-height: 15px;
                color: var(--cart-item-price-discount-color);
                margin-inline-end: 0;
            }
        }

        &_isCartOverlay {
            data {
                font-size: 20px;
            }
        }
    }

    &-Heading,
    &-SubHeading {
        display: -webkit-box;
        height: min-content;
        line-height: 18px;
        line-clamp: 2;
        white-space: unset;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
    }
}
